<template>
  <div id="contactEntriesCard" class="w-100">
    <!--Delete Dialog -->
    <v-dialog v-model="deleteEntryDialog" persistent max-width="590">
      <v-card v-if="selectedEntry" :loading="loading || superLoading">
        <v-card-title class="headline">
          Are you sure you want to delete this entry ?
        </v-card-title>
        <v-card-subtitle class="mt-1">
          <b>Entry Subject:</b> {{ entriesObj[selectedEntry].subject }}
        </v-card-subtitle>
        <v-card-text>
          This action cannot be reversed. Please click the "DELETE" button to
          confirm.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            dark
            :loading="loading || superLoading"
            @click="deleteEntry()"
          >
            <v-icon class="mr-1">mdi-trash-can-outline</v-icon> Delete
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading || superLoading"
            @click="deleteEntryDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Display container with error, questions display, nav buttons -->
    <v-container class="mt-4 pa-0 pa-md-4">
      <!-- Error Row -->
      <v-row align="center" no-gutters v-if="error">
        <v-col cols="12" class="mt-2">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>

      <!-- Display Entries Row w/ cards -->
      <v-row align="center" justify="center" no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="9"
          lg="7"
          v-for="(entry, id) in entriesObj"
          :key="id"
        >
          <div class="d-flex">
            <!-- Entry Card -->
            <v-card
              class="mx-1 my-4 w-100"
              color="grey darken-3"
              dark
              outlined
              elevation="0"
              :disabled="loading || superLoading"
            >
              <!-- Date-time chip -->
              <v-card-subtitle class="my-n2">
                <span class="d-md-flex align-center text-capitalize">
                  <v-chip small class="ma-1">
                    <b class="green--text text--darken-1 mr-1">Date-time:</b>
                    {{ dateTimeText(entry.datetime.seconds * 1000) }}
                  </v-chip>

                  <v-spacer></v-spacer>
                </span>
              </v-card-subtitle>

              <v-divider></v-divider>

              <!-- Entry Body -->
              <v-card-text class="text-subtitle-1 ml-2">
                <!-- Full Name Div -->
                <div>
                  <div class="green--text text--darken-1 mr-1">
                    <small class="text-caption"> Name: </small>
                  </div>
                  <span v-html="entry.fullName"></span>
                </div>

                <!-- Email Div -->
                <div>
                  <div class="green--text text--darken-1 mr-1">
                    <small class="text-caption"> Email: </small>
                  </div>
                  <span v-html="entry.email"></span>
                </div>

                <!-- Phone Div -->
                <div>
                  <div class="green--text text--darken-1 mr-1">
                    <small class="text-caption"> Phone: </small>
                  </div>
                  <span v-html="entry.phone"></span>
                </div>

                <!-- Subject Div -->
                <div>
                  <div class="green--text text--darken-1 mr-1">
                    <small class="text-caption"> Subject: </small>
                  </div>
                  <span v-html="entry.subject"></span>
                </div>

                <!-- Message Div -->
                <div>
                  <div class="green--text text--darken-1 mr-1">
                    <small class="text-caption"> Message: </small>
                  </div>
                  <span v-html="entry.message"></span>
                </div>

                <v-divider class="mt-2"></v-divider>
              </v-card-text>

              <!-- Delete Button -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- Delete Button -->
                <v-btn
                  class="ml-md-1 ml-n1 mr-1"
                  color="green lighten-1"
                  @click="deleteEntryModal(id)"
                  :disabled="loading || superLoading"
                  outlined
                  small
                >
                  <v-icon class="mr-1">mdi-trash-can-outline mdi-18px</v-icon>
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
const dayjs = require("dayjs");

export default {
  name: "ContactEntriesCard",
  props: ["superLoading", "entriesObj"],
  data: () => ({
    loading: true,
    deleteEntryDialog: false,
    error: "",
    selectedEntry: "",
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    dateTimeText(timestamp) {
      return dayjs(timestamp).format("hh:mm a, D MMM");
    },

    deleteEntryModal(docID) {
      this.selectedEntry = docID;
      this.deleteEntryDialog = true;
    },

    deleteEntry() {
      this.setLoading(true);
      this.$emit("deleteEntry", this.selectedEntry);
    },

    closeDeleteModal() {
      this.selectedEntry = "";
      this.deleteEntryDialog = false;
      this.setLoading(false);
    },
  },
  mounted() {
    this.setLoading(false);
  },
};
</script>