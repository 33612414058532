<template>
  <div id="contactForm" class="grey lighten-3 fill-height">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-container>
      <!-- title -->
      <v-row justify="center">
        <h1 class="text-title my-9">
          <v-icon class="text-h3 mr-2" color="green darken-1">
            mdi-clipboard-text-multiple
          </v-icon>
          Contact Form
        </h1>
      </v-row>

      <!-- Error Row -->
      <div v-if="error" class="mt-1 mb-5 px-1 px-md-3">
        <v-alert type="error">{{ error }}</v-alert>
      </div>

      <v-row justify="center" class="my-4">
        <v-alert type="info">
          Please keep deleting old/valueless messages for cost efficiency.
        </v-alert>
      </v-row>

      <!-- Message Cards -->
      <ContactEntriesCard
        ref="contactEntriesCard"
        :superLoading="superLoading"
        :entriesObj="entriesObj"
        @setSuperLoading="setSuperLoading"
        @deleteEntry="deleteEntry"
      />
    </v-container>
  </div>
</template>

<script>
import ContactEntriesCard from "@/components/Home/View/ContactForm/ContactEntriesCard.vue";

export default {
  name: "ContactForm",
  metaInfo: {
    title: "Answer Sheets",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Contact Form Page description lorem ipsum dolor sit amet.",
      },
    ],
  },

  components: {
    ContactEntriesCard,
  },

  data: () => ({
    superLoading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    entriesObj: null,
  }),
  methods: {
    setSuperLoading(value) {
      this.superLoading = value;
    },

    fetchContactFormEntries() {
      this.setSuperLoading(true);

      //get contact form entries from server
      this.$store
        .dispatch("getContactFormEntries")
        .then((res) => {
          if (res) {
            this.entriesObj = res;
          }
        })
        .catch((err) => {
          this.error = err;
        })
        .finally(() => {
          this.setSuperLoading(false);
        });
    },

    deleteEntry(docId) {
      this.setSuperLoading(true);

      this.$store
        .dispatch("deleteContactFormEntry", docId)
        .then(() => {
          this.snackbarText = "Entry deleted successfully :)";
          this.$refs.contactEntriesCard.closeDeleteModal();
          //remove from entriesObj
          this.$delete(this.entriesObj, docId);
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setSuperLoading(false);
        });
    },
  },
  mounted() {
    this.fetchContactFormEntries();
  },
};
</script>